import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { navigate } from 'gatsby'
import { FormContainer, Layout, Login, SignUp, SEO } from 'components'
import { isNil } from 'lodash'

import {
  updateToLoginOption,
  updateToSignupOption,
} from 'actions/signupActions'
import { formStyle } from 'components/formStyle'
import { HOME_ENDPOINT, ONBOARDING_ENDPOINT } from 'constants/apiUrls'

const SignUpPage = () => {
  const classes = formStyle()
  const loggedUser = useSelector(state => state.userState.user)
  const option = useSelector(state => state.signupState.option)

  useEffect(() => {
    if (loggedUser && !loggedUser.isAnonymous && !isNil(loggedUser.onboarded)) {
      loggedUser.onboarded
        ? navigate(HOME_ENDPOINT)
        : navigate(ONBOARDING_ENDPOINT)
    }
  }, [loggedUser])

  const updateOption = newValue => {
    if (newValue === 1) {
      updateToLoginOption()
    } else {
      updateToSignupOption()
    }
  }

  const forms = [
    {
      label: 'Sign up',
      component: <SignUp />,
    },
    {
      label: 'Login',
      component: <Login />,
    },
  ]
  return (
    <Layout>
      <SEO title="SignUp" />
      <div className={classes.signUpFormContent}>
        <FormContainer
          forms={forms}
          className={classes.formContainer}
          tabOption={option}
          updateOption={updateOption}
        />
      </div>
    </Layout>
  )
}

export default SignUpPage
